<template>
  <div class="tokyo_tm_about" v-if="data">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>About</span>
          <h3>About the company</h3>
        </div>
      </div>
    </div>
    <div class="about_image">
      <img :src="data.attributes.CoverImage.data.attributes.formats.large.url" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <div class="description_inner">
        <div>
          {{ data.attributes.Overview }}
        </div>
        <ul class="team">
          <li v-for="(member, index) in data.attributes.members.data" :key="index" :class="index % 2 == 1 ? 'alt' : ''">
            <img v-if="member.attributes.Photo.data.attributes.formats.small != null" :src="member.attributes.Photo.data.attributes.formats.small.url" />
            <div>
              <span class="name">{{ member.attributes.Name }}</span>
              <span class="role">{{ member.attributes.Role }}</span>
              <p>{{ member.attributes.Bio }}</p>
              <a v-if="member.attributes.Email != null" :href="'mailto:' + member.attributes.Email">Contact</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Modalbox -->
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        data: null,
        isActive: false,
        isVisible: false,
        coverImage: require("../assets/img/about/cover.jpg"),
      };
    },
    methods: {
      showModal: function() {
        this.isActive = true;
        this.isVisible = true;
      },
      closeModal: function() {
        this.isActive = false;
        this.isVisible = false;
      },
    },
    mounted() {
      axios
      .get('https://cttc-strapi.herokuapp.com/api/about?populate[0]=CoverImage&populate[1]=members&populate[2]=members.Photo')
      .then((response) => {
        this.data = response.data.data;
      });
    }
  };
</script>

<style lang="scss"></style>
