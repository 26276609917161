<template>
  <div class="tokyo_tm_news">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>News</span>
          <h3>Latest News</h3>
        </div>
      </div>
    </div>
    <ul class="tokyo_tm_news_inner">
      <div v-if="articles.length == 0" class="tabcontent">
        <li v-for="(i) in 4" :key="i">
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="360px"
            animation="fade"
          />
        </li>
      </div>
      <router-link v-for="(article, index) in articles" :key="index" :to="'/article/' + article.attributes.Slug">
        <li>
          <div class="list_inner">
            <div class="image">
              <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
              <div
                class="main"
                v-if="article.attributes.Cover.data"
                :style="{ backgroundImage: 'url(' + (article.attributes.Cover.data.attributes.formats.medium ? article.attributes.Cover.data.attributes.formats.medium.url : article.attributes.Cover.data.attributes.url) + ')' }"
              ></div>
            </div>
            <!-- End .image -->
            <div class="details">
              <div class="extra">
                <p class="date">
                  <span>{{ article.attributes.Date }}</span>
                </p>
              </div>
              <!-- End meta info -->
              <h3 class="title">
                {{ article.attributes.Title }}
              </h3>
              <div class="tokyo_tm_read_more">
                <a><span>Read More</span></a>
              </div>
            </div>
            <!-- End .details -->
          </div>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        articles: []
      };
    },
    mounted() {
      axios
      .get('https://cttc-strapi.herokuapp.com/api/blogs?fields[0]=title&fields[1]=slug&fields[2]=date&populate=*&sort=id:desc')
      .then((response) => {
        response.data.data.map((item) => this.articles.push(item));
      });
    }
  };
</script>

<style lang="scss" scoped></style>
