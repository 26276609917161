<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Productions</span>
          <h3>The things we've staged</h3>
        </div>
        <!-- End Left -->
        <!-- Leaving these items in case we want a filter one day -->
        <!--<div class="portfolio_filter">
          <ul>
            <li>
              <a
                v-on:click="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
                >All</a
              >
            </li>
            <li>
              <a
                v-on:click="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
                >Cabarets</a
              >
            </li>
            <li>
              <a
                v-on:click="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
                >Musicals</a
              >
            </li>
            <li>
              <a
                v-on:click="activetab = 4"
                :class="[activetab === 4 ? 'active' : '']"
                >Plays</a
              >
          </ul>
        </div>-->
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <div v-if="allItems.length == 0" class="tabcontent">
      <ul class="portfolio_list">
        <li v-for="(i) in 4" :key="i">
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="500px"
            animation="fade"
          />
        </li>
      </ul>
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null"></CoolLightBox>
        <li
          v-for="(item, index) in allItems"
          :key="index"
          @click="index = index"
        >
          <router-link :to="/production/ + item.attributes.Slug">
            <div class="inner">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="item.attributes.Cover.data.attributes.formats.small.url" alt="Portfolio" />
              </div>
              <div class="details">
                <!-- End meta info -->
                <h3 class="title">
                  {{ item.attributes.Title }}
                </h3>
                <div class="tokyo_tm_read_more">
                  <a><span>Read More</span></a>
                </div>
              </div>
            </div>
          </router-link>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="caberetItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in caberetItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox
          :items="musicalItems"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in musicalItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 4" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="playItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in playItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 4 -->

    <div v-if="activetab === 5" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox
          :items="galleryItems"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>

        <li
          v-for="(image, imageIndex) in galleryItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 5 -->
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        activetab: 1,
        allItems: [],
        // leaving these other items in case we want a filter some day
        musicalItems: [],
        caberetItems: [],
        playItems: [],
        galleryItems: [],
        index: null,
      };
    },
    mounted() {
      axios
      .get('https://cttc-strapi.herokuapp.com/api/shows?fields[0]=title&fields[1]=slug&populate=*&sort=id:desc')
      .then((response) => {
        this.allItems = [];
        response.data.data.map((item) => this.allItems.push(item));
      });
    }
  };
</script>

<style lang="scss" scoped></style>
