<template>
    <div class="tokyo_tm_production">
        <div class="tokyo_tm_title">
            <div v-if="!data" id="skeleton" class="left">
                <vue-skeleton-loader
                    type="rect"
                    width="120px"
                    height="35px"
                    animation="fade"
                />
                <div style="height: 10px" />
                <vue-skeleton-loader
                    type="rect"
                    width="100%"
                    height="500px"
                    animation="fade"
                />
                <div style="height: 40px" />
                <vue-skeleton-loader
                    type="rect"
                    width="500px"
                    height="50px"
                    animation="fade"
                />
                <div style="height: 40px" />
                <vue-skeleton-loader
                    type="rect"
                    width="100%"
                    height="250px"
                    animation="fade"
                />
            </div>
            <div class="title_flex">
                <div v-if="data" class="left">
                    <span>Production</span>
                    <div v-if="data.attributes.Header.data" class="cover_image" :style="{ backgroundImage: 'url(' + this.cover + ')' }"></div>
                    <h2>{{ data.attributes.Title }}</h2>
                    <div class="summary" v-html="data.attributes.DescriptionParsed"></div>
                    <a class="booking" v-if="data.attributes.BookingLink != null" :href="data.attributes.BookingLink">Book now</a>

                    <ul class="gallery" v-if="data.attributes.Photos">
                        <CoolLightBox :items="galleryItems" :index="index" @close="index = null"></CoolLightBox>
                        <li
                            v-for="(image, imageIndex) in data.attributes.Photos ? data.attributes.Photos.data : []"
                            :key="imageIndex"
                            @click="index = imageIndex"
                        >
                        <div class="inner">
                            <div class="entry tokyo_tm_portfolio_animation_wrap">
                                <img class="image" :src="image.attributes.formats.small.url" alt="Portfolio" />
                            </div>
                        </div>
                        </li>
                        <!-- End li -->
                    </ul>

                    <div class="clearfix"></div>
                    <div class="summary" v-html="data.attributes.DetailsParsed"></div>

                </div>
            </div>
        </div>
        <!-- End tokyo_tm_title -->

        
    </div>
</template>

<script>
    import axios from 'axios';
    import { marked } from 'marked';
    export default {
        data() {
            return {
                data: null,
                index: null,
                galleryItems: [],
            };
        },
        mounted() {
            var slug = this.$route.params.id;
            axios
            .get(`https://cttc-strapi.herokuapp.com/api/shows/${slug}?populate=*`)
            .then((response) => {
                //console.log(response);
                this.data = response.data.data;
                this.galleryItems = this.data.attributes.Photos.data ? this.data.attributes.Photos.data.map(image => image.attributes.formats.large.url) : [];
                this.data.attributes.DescriptionParsed = marked.parse(this.data.attributes.Description);
                this.data.attributes.DetailsParsed = marked.parse(this.data.attributes.Details);
                //this.cover = this.data.attributes.Header.data.attributes.formats.large.url;
                if (this.data.attributes.Header.data.attributes.formats.large) this.cover = this.data.attributes.Header.data.attributes.formats.large.url;
                else if (this.data.attributes.Header.data.attributes.formats.medium) this.cover = this.data.attributes.Header.data.attributes.formats.medium.url;
                else this.cover = this.data.attributes.Header.data.attributes.formats.small.url;
                console.log(this.cover);
            });
        },
    };
</script>

<style lang="scss" scoped></style>
