<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Contact</span>
          <h3>Get in Touch</h3>
          <p style="margin-top: 30px">Looking to reach out? Pop us a mail to <a href="mailto:info@capetowntheatrecompany.org">info@capetowntheatrecompany.org</a> or use the form below and one of our team will get back to you.</p>
          <p style="margin-top: 30px">If you'd like to submit a production proposal for us to look at, you can do so using <a href="https://docs.google.com/forms/d/e/1FAIpQLSeWyNV43aqJcrrhYr_lzf95AopiU9uqkvl-du5jJ0quV5YqqQ/viewform">this form.</a></p>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <!--<div class="map_wrap">
      <iframe
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d206252.721472711!2d-115.31508339643749!3d36.12519578053308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80beb782a4f57dd1%3A0x3accd5e6d5b379a3!2sLas%20Vegas%2C%20NV%2C%20USA!5e0!3m2!1sen!2sbd!4v1622253976478!5m2!1sen!2sbd"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div> -->
    <!-- End Google Map -->

    <div class="fields" :style="{ display: sent ? 'none' : 'block' }">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="contact_form" @submit.prevent="handleSubmit(onSubmit)">
          <div class="first">
            <ul>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    v-model="formData.name"
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="Email"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <textarea
                    v-model="formData.message"
                    placeholder="Message"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="ib-button" :disabled="sending">{{ buttonText }}</button>
          </div>
          <span class="error">{{ error }}</span>
        </form>
      </ValidationObserver>
    </div>
    <span class="success" :style="{ display: sent ? 'block' : 'none' }">Sent! Thanks for reaching out to us, we'll be in touch soon!</span>
    <!-- END FIELDS -->
  </div>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import { initializeApp } from "firebase/app";
  import { getDatabase, ref, push } from "firebase/database";
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    computed: {
      buttonText: function () {
        return this.sending ? "Sending..." : "Send Message";
      }
    },
    data() {
      return {
        sending: false,
        sent: false,
        error: null,
        formData: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        this.sending = true;
        const config = {
          apiKey: "AIzaSyBpaalsXcWxjHqSQpBBsr1oyS0DdiW44sk",
          authDomain: "cttc-6b725.firebaseapp.com",
          databaseURL: "https://cttc-6b725-default-rtdb.europe-west1.firebasedatabase.app",
          projectId: "cttc-6b725",
          storageBucket: "cttc-6b725.appspot.com",
          messagingSenderId: "237978985715",
          appId: "1:237978985715:web:838762e9559f3e4b32c386",
          measurementId: "G-KV3GMWYVVC"
        }

        const app = initializeApp(config);
        const db = getDatabase(app);

        push(ref(db, 'emails'), this.formData)
          .then(() => {
            this.sent = true;
          })
          .catch(() => {
            this.error = "Oops, there was a problem sending your message! Please try again later or send an email directly to us instead.";
            this.sending = false;
          });
        
      },
    },
  };
</script>

<style scoped>
  .error {
    color: red;
    font-weight: bold;
  }

  .success {
    font-weight: bold;
  }
</style>
