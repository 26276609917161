import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound.vue'
import Home from '../components/Home';
import About from '../components/About';
import Productions from '../components/Productions';
import Show from '../components/Show';
import News from '../components/News';
import Contact from '../components/Contact';
import Article from '../components/Article';
import Members from '../components/Members';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "HomeLight",
    meta: {
      title: 'Home'
    },
    component: () => import("../views/HomeLight.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: Home
      },
      {
        path: "about",
        name: "about",
        component: About,
        meta: {
          title: 'About'
        }
      },
      {
        path: "productions",
        name: "productions",
        component: Productions,
        meta: {
          title: 'Productions'
        }
      },
      {
        path: "news",
        name: "news",
        component: News,
        meta: {
          title: 'News'
        }
      },
      {
        path: "contact",
        name: "contact",
        component: Contact,
        meta: {
          title: 'Contact'
        }
      },
      {
        path: "join",
        name: "members",
        component: Members,
        meta: {
          title: 'Join'
        }
      },
      {
        path: "article/:id",
        component: Article
      },
      {
        path: "production/:id",
        component: Show
      }
    ]
  },

  {
    path: '*',
    name: NotFound,
    meta: {
      title: '404'
    },
    component: () => import("../views/NotFound.vue")
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Cape Town Theatre Company';
  }
  else {
    document.title = 'Cape Town Theatre Company';
  }
});


export default router;