<template>
  <div class="tokyo_tm_home">
    <div class="home_content" v-if="data != null">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url(' + logo + ')' }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">{{ data.attributes.Title }}</h3>
        <p class="job">{{ data.attributes.Tagline }}</p>

        <Social :facebook="data.attributes.FacebookLink" :instagram="data.attributes.InstagramLink" :mailingList="data.attributes.MailingListLink" />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Social from "./Social";
  export default {
    components: {
      Social,
    },
    data() {
      return {
        logo: require("../assets/img/logo/cttc_logo_white_shadow.png"),
        data: null
      };
    },
    mounted() {
      axios
      .get('https://cttc-strapi.herokuapp.com/api/home')
      .then((response) => {
        this.data = response.data.data;
      });
    }
  };
</script>

<style lang="scss">
  @font-face {
    font-family: 'Futura';
    src: local("Futura"), url(../assets/futura.ttf) format("truetype");
  }

  .home_content .name {
    font-family: 'Futura';
    letter-spacing: 10px;
  }
</style>