<template>
  <div id="app" class="tokyo_tm_all_wrap">
    <router-view></router-view>
  </div>
</template>

<script>
  import { initializeApp } from "firebase/app";
  import { getAnalytics } from "firebase/analytics";
  export default {
    components: {},
    created() {
      const config = {
        apiKey: "AIzaSyBpaalsXcWxjHqSQpBBsr1oyS0DdiW44sk",
        authDomain: "cttc-6b725.firebaseapp.com",
        databaseURL: "https://cttc-6b725-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "cttc-6b725",
        storageBucket: "cttc-6b725.appspot.com",
        messagingSenderId: "237978985715",
        appId: "1:237978985715:web:838762e9559f3e4b32c386",
        measurementId: "G-KV3GMWYVVC"
      }

      const app = initializeApp(config);
      getAnalytics(app);
    }
  };
</script>
