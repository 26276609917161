<template>
  <div class="tokyo_tm_article" v-if="data">
    <div v-if="!data" id="skeleton" class="left">
      <vue-skeleton-loader
          type="rect"
          width="120px"
          height="35px"
          animation="fade"
      />
      <div style="height: 10px" />
      <vue-skeleton-loader
          type="rect"
          width="500px"
          height="50px"
          animation="fade"
      />
      <div style="height: 50px" />
      <vue-skeleton-loader
          type="rect"
          width="100%"
          height="300px"
          animation="fade"
      />
      <div style="height: 40px" />
      <vue-skeleton-loader
          type="rect"
          width="100%"
          height="500px"
          animation="fade"
      />
    </div>
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Article</span>
          <h3 class="title">
            {{ data.attributes.Title }}
          </h3>
        </div>
      </div>
    </div>
    <div class="extra">
      <p class="date"><span>{{ data.attributes.Date }}</span></p>
    </div>
    <div class="image" v-if="data.attributes.Cover.data">
      <div
        class="main"
        :style="{ backgroundImage: 'url(' + data.attributes.CoverImage + ')' }"
      ></div>
    </div>
    <!-- END DETAILS -->
    <div class="main_content">
      <div class="descriptions" v-html="data.attributes.ContentParsed"></div>

      <div class="news_share" style="display: none">
        <span>Share:</span>
        <Social />
      </div>
    </div>
  </div>
</template>

<script>
  import Social from "../components/Social";
  import axios from 'axios';
  import { marked } from 'marked';
  export default {
    components: {
      Social
    },
    props: ['title', 'date', 'image'],
    data() {
      return {
        data: null,
      }
    },
    mounted() {
      var slug = this.$route.params.id;
      axios
      .get(`https://cttc-strapi.herokuapp.com/api/blogs/${slug}?populate=*`)
      .then((response) => {
        console.log(response);
        this.data = response.data.data;
        this.data.attributes.ContentParsed = marked.parse(this.data.attributes.Content ?? "");
        this.data.attributes.CoverImage = this.data.attributes.Cover.data.attributes.formats.large ? this.data.attributes.Cover.data.attributes.formats.large.url : this.data.attributes.Cover.data.attributes.url;
      });
    },
  }
</script>