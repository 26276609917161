<template>
  <ul class="social">
    <li>
      <a :href="facebook" target="_blank" rel="noreferrer">
        <img class="svg" :src="facebookIcon" alt="social" />
      </a>
    </li>
    <li>
      <a :href="instagram" target="_blank" rel="noreferrer">
        <img class="svg" :src="instagramIcon" alt="social" />
      </a>
    </li>
    <li>
      <a :href="mailingList" target="_blank" rel="noreferrer">
        <img class="svg" :src="mailingListIcon" alt="social" />
      </a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
  export default {
    props: {
      facebook: String,
      instagram: String,
      mailingList: String,
    },
    data() {
      return {
        facebookIcon: require("../assets/img/svg/social/facebook.svg"),
        instagramIcon: require("../assets/img/svg/social/instagram.svg"),
        mailingListIcon: require("../assets/img/svg/mail.svg"),
      };
    },
  };
</script>

<style lang="scss" scoped></style>
