var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokyo_tm_contact"},[_vm._m(0),_c('div',{staticClass:"fields",style:({ display: _vm.sent ? 'none' : 'block' })},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact_form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"first"},[_c('ul',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Name","autocomplete":"off"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"Message"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('div',{staticClass:"tokyo_tm_button"},[_c('button',{staticClass:"ib-button",attrs:{"type":"submit","disabled":_vm.sending}},[_vm._v(_vm._s(_vm.buttonText))])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))])])]}}])})],1),_c('span',{staticClass:"success",style:({ display: _vm.sent ? 'block' : 'none' })},[_vm._v("Sent! Thanks for reaching out to us, we'll be in touch soon!")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokyo_tm_title"},[_c('div',{staticClass:"title_flex"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("Contact")]),_c('h3',[_vm._v("Get in Touch")]),_c('p',{staticStyle:{"margin-top":"30px"}},[_vm._v("Looking to reach out? Pop us a mail to "),_c('a',{attrs:{"href":"mailto:info@capetowntheatrecompany.org"}},[_vm._v("info@capetowntheatrecompany.org")]),_vm._v(" or use the form below and one of our team will get back to you.")]),_c('p',{staticStyle:{"margin-top":"30px"}},[_vm._v("If you'd like to submit a production proposal for us to look at, you can do so using "),_c('a',{attrs:{"href":"https://docs.google.com/forms/d/e/1FAIpQLSeWyNV43aqJcrrhYr_lzf95AopiU9uqkvl-du5jJ0quV5YqqQ/viewform"}},[_vm._v("this form.")])])])])])}]

export { render, staticRenderFns }